import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ANIMATION, BREAKPOINTS, LAYOUT } from '../../styles/cssVariables'
import IconLinkArrowSvg from '../../images/icons/link-arrow.svg'


const LinkContainer = styled.span`
  box-sizing: border-box;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;



  ${(props) =>
    props.type === 'link' &&
    `
      background-color: transparent;
        cursor: pointer;
        transition: color 275ms ease;
        text-deocration: none;
        &:after {
          display: block;
          content: "";
          border-bottom: 2px solid var(--color-violet);
          transform: scaleX(0);
          transition: transform 275ms ease-in-out;
          transform-origin: 100% 50%;
          margin-top: 0.3em;
        }
        &:hover:after {
          transform: scaleX(1);
          transform-origin: 0% 50%;
        }
      
         
      
      
    `}

  ${(props) =>
    props.type === 'solid' &&
    `
     
      padding: 1.25em 2.25em;
      border-radius: 4px;
      border: 1px solid var(--color-skobeloff);
      background-color: var(--color-skobeloff);
      color:  var(--color-white);

      transition: color ${ANIMATION.speedMedium} ease,
        background-color ${ANIMATION.speedMedium} ease;

      &:hover {
        background-color: transparent;
        color:  var(--color-skobeloff);
      }

      @media (max-width: ${BREAKPOINTS.lrg}px) {
        padding: 1em 1.5em;
      }
  `}

  ${(props) =>
    props.type === 'inverted' &&
    `
    
      padding: 1.25em 2.25em;
      border-radius: 4px;
  
      background-color: #fff;
      color: var(--color-heading);

      font-weight: 500;

      transition: color ${ANIMATION.speedMedium} ease,
        background-color ${ANIMATION.speedMedium} ease;

      &:hover {
        color: var(--color-blue);
      }

      @media (max-width: ${BREAKPOINTS.lrg}px) {
        padding: 1em 1.5em;
      }
  `}

  ${(props) =>
    props.type === 'outline' &&
    `

      padding: 1.25em 2.25em;
      border: 1px solid var(--color-blue);
      border-radius: 4px;
  
      color: #fff;
      background-color: transparent;

      transition: color ${ANIMATION.speedMedium} ease,
        background-color ${ANIMATION.speedMedium} ease;

      &:hover {
        background-color: var(--color-blue);
        color: #fff;
      }

      @media (max-width: ${BREAKPOINTS.lrg}px) {
        padding: 1em 1.5em;
      }
  `}
`


const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`
/**
 * Renders a button in various styles.
 * Accepts a prop that changes the look of the button.
 * Child element should be a Gatsby Link component or an a tag.
 */
const Button = (props) => {
  return (
    <LinkContainer className={props.className} type={props.type}>
      {props.children}
      {props.arrow && <Arrow />}
    </LinkContainer>
  )
}

Button.propTypes = {
  /** If true, renders an arrow after this button's text */
  arrow: PropTypes.bool,
  /** Passed to styled-components to control the style of this button. */
  type: PropTypes.oneOf(['link', 'solid', 'inverted', 'outline']).isRequired,
}

Button.defaultProps = {
  arrow: true,
  type: 'link',
}

export default Button
