import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TagContainer = styled.span`
  height: fit-content;
  padding: 9px 13px;
  margin: 0 6px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  font-size: 12px;
  line-height: 1;

  a {
    font-size: 12px;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  > a {
    font-size: 12px;
  }

  ${(props) =>
    props.type === 'search' &&
    `
    border-color: rgba(18, 184, 178, 0.35);
    color: var(--color-heading);
  `}

  ${(props) =>
    props.type === 'spotlight' &&
    `
    border-color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.4);
    color: #fff;
  `}

${(props) =>
    props.type === 'expertise' &&
    `
    border-color: var(--color-violet);
    background-color: rgba(249, 165, 43, 0.1);
    color: var(--color-heading);
  `}

${(props) =>
    props.type === 'impact' &&
    `
    border-color: var(--color-blue);
    background-color: rgba(19, 183, 179, 0.1);
    color: var(--color-heading);
  `}
`

/**
 * Renders small text on a coloured background with a border in a variety of styles.
 * Used in e.g. directory cards, directory pages, education hub tags, etc.
 */
const Tag = (props) => {
  return (
    <TagContainer className={props.className} type={props.type}>
      {props.children}
    </TagContainer>
  )
}

Tag.propTypes = {
  /** Passed to styled-components to influence visual style */
  type: PropTypes.oneOf(['expertise', 'impact', 'search', 'spotlight']),
}

export default Tag
