import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import slugify from 'slugify'

import Tag from './tag'
import SvgProfileLocation from '../../images/icons/profile-location.svg'
import SvgProfileSize from '../../images/icons/profile-size.svg'
import SvgFoundingMember from '../../images/icons/founding-member.svg'
import Button from './button'
import { BREAKPOINTS, ANIMATION } from '../../styles/cssVariables'
import IconLinkArrowSvg from '../../images/icons/link-arrow.svg'


const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 38px 30px 38px 40px;
  border: 1px solid rgba(229, 234, 244, 0.5);
  border-radius: 4px;

  background-color: #fff;

  filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 24px 22px 32px;
  }
`

const ContainerBrand = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled(GatsbyImage)`
  width: 55px;
  margin-right: 20px;
  border-radius: 4px;
`

const ContainerName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    margin-bottom: 18px;
  }
`

const FounderIcon = styled(SvgFoundingMember)`
  height: 20px;
  width: 20px;
  margin-left: 0.5em;
`

const ContainerTags = styled.div`
  margin: 7px -3px 0;
`

const TagCompressed = styled(Tag)`
  display: inline-block;
  margin: 3px !important;
  padding: 4px 8px 5px;
  line-height: 1.6;
`

const BioExcerpt = styled.p`
  margin-top: 32px;
  margin-bottom: 30px;
  color: var(--color-body);

  font-size: 14px;
  line-height: 1.7;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 20px;
  }
`

const ContainerMetadata = styled.div`
  margin-top: auto;
`

const IconMetadata = styled.span`
  font-size: 13px;
  color: var(--color-heading);

  &:not(:last-child) {
    margin-right: 2em;
  }

  svg {
    margin-right: 0.5em;
    vertical-align: middle;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-right: 0;
    margin-bottom: 9px;
    display: block;
  }
`

const ContainerContact = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  a {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 22px;
  }
`

const ContactLink = styled(Button)`
  height: fit-content;
  margin-left: 30px;
  color: var(--color-heading);
`

const CTAButton = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  background-color: var(--color-skobeloff);
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-skobeloff) !important;

  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`

const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

const ProfileName = styled.h2`
  font-size: 15px;
  line-height: 1.5;
  color: var(--color-heading);


`

/**
 * Renders a profile card in the Directory feed.
 * Accepts a Profile data type from the CMS.
 */
const ProfileCard = (props) => {
  const profile = props.profile.node ? props.profile.node : props.profile
  return (
    <Card>
      <ContainerBrand>
        {profile.logo && (
          <Logo alt={profile.logo.title} image={profile.logo.gatsbyImageData} />
        )}

        <div>
          <ContainerName>
            <ProfileName>{profile.name}</ProfileName>
            {profile.foundingMember && <FounderIcon />}
          </ContainerName>
          {(profile.primaryExpertise || profile.primaryImpact) && (
            <ContainerTags>
              <TagCompressed type="expertise">
                {profile.primaryExpertise}
              </TagCompressed>
              <TagCompressed type="impact">
                {profile.primaryImpact}
              </TagCompressed>
            </ContainerTags>
          )}
        </div>
      </ContainerBrand>

      {profile.bioExcerpt && <BioExcerpt>{profile.bioExcerpt}</BioExcerpt>}

      {(profile.locationData || profile.teamSize) && (
        <ContainerMetadata>
          {profile.locationData && (
            <IconMetadata>
              <SvgProfileLocation />
              {profile.locationData.locationText}
            </IconMetadata>
          )}
          <IconMetadata>
            <SvgProfileSize />
            {profile.teamSize}
          </IconMetadata>
        </ContainerMetadata>
      )}

      <ContainerContact>
        <CTAButton to={`/directory/${profile.slug}`}>
           View Profile
           <Arrow />
        </CTAButton>
        

                
      </ContainerContact>
    </Card>
  )
}

ProfileCard.propTypes = {
  /** Profile data type from CMS */
  profile: PropTypes.shape({
    node: PropTypes.shape({
      /** The agency name */
      name: PropTypes.string.isRequired,
      /** The slug to use in the URL for this agency's page */
      slug: PropTypes.string.isRequired,
      /** Agency logo, will be cropped to square for the directory */
      logo: PropTypes.shape({
        title: PropTypes.string,
        gatsbyImageData: PropTypes.object,
      }),
      /** If true, will render the "founding member" icon next to their logo */
      foundingMember: PropTypes.bool,
      /** Category - used for filtering */
      category: PropTypes.oneOf(['Service', 'Tech', 'Operations']).isRequired,
      /** Expertise - used for filtering */
      primaryExpertise: PropTypes.string.isRequired,
      /** Impact - used for filtering */
      primaryImpact: PropTypes.string.isRequired,
      /** Expertise - listed on profile */
      secondaryExpertise: PropTypes.arrayOf(PropTypes.string).isRequired,
      /** Impact - listed on profile */
      secondaryImpact: PropTypes.arrayOf(PropTypes.string).isRequired,
      /** Shows team size on directory */
      teamSize: PropTypes.string,
      /** Shows location on directory & used for filtering */
      locationData: PropTypes.shape({
        locationText: PropTypes.string,
      }),
     

      /** Short text shown on directory */
      bioExcerpt: PropTypes.string,
    }),
  }),
}

export default ProfileCard
