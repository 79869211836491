import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import SvgSearch from '../../images/icons/search.svg'
import { ANIMATION, BREAKPOINTS } from '../../styles/cssVariables'

const SearchBar = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 12px 15px 10px 29px;
  border: 3px solid #fff;
  border-radius: 4px;

  background-color: #fff;

  transform: translateY(18px);
  transition: border-color ${ANIMATION.speedMedium} ease;
  filter: drop-shadow(0 50px 60px rgba(0, 0, 0, 0.08));
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.isFocused &&
    `
    border-color: var(--color-skobeloff);
  `}

  @media (max-width: ${BREAKPOINTS.tbl}px) {
    padding: 12px 12px 8px 14px;

    width: 90%;
    transform: translateY(50%);
  }

`

const QueryInput = styled.input`
  flex-grow: 1;

  border: none;
  outline: none;

  font-size: 17px;
  color: var(--color-heading);

  &::placeholder {
    color: var(--color-body);
  }
  &::-webkit-search-cancel-button {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 14px;
  }
`

const QuerySubmit = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;

  cursor: pointer;

  &:hover {
    #search__blob {
      fill: var(--color-skobeloff);
    }
  }

  #search__blob {
    transition: fill ${ANIMATION.speedMedium} ease;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    svg {
      height: 45px;
      
    }
  }
`

const Search = ({ className, initialQuery = '' }) => {
  const [isFocused, updateFocus] = useState(false)
  const [query, setQuery] = useState(initialQuery)
  const inputEl = useRef(null)

  /**
   * Updates the state to the current query, which updates search results
   * @param {ChangeEvent} e
   */
  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  /**
   * Navigates to /search when the form is submitted
   * query q paramenter equal to the value within the input search
   * @param {SubmitEvent} e
   */
  const handleSubmit = (e) => {
    e.preventDefault()
    // `inputEl.current` points to the mounted search input element
    const q = inputEl.current.value
    navigate(`/search?q=${q}`)
  }

  return (
    <SearchBar
      className={className}
      isFocused={isFocused}
      onSubmit={handleSubmit}
      role="search"
    >
      <QueryInput
        aria-label="What are you looking for?"
        onBlur={() => updateFocus(false)}
        onChange={handleChange}
        onFocus={() => updateFocus(true)}
        placeholder="What are you looking for?"
        ref={inputEl}
        role="searchbox"
        type="search"
        value={query}
      />
      <QuerySubmit aria-label="search" type="submit">
        <SvgSearch />
      </QuerySubmit>
    </SearchBar>
  )
}

Search.propTypes = {
  /** The query at the time of rendering - will display in the input element */
  initialQuery: PropTypes.string,
}

export default Search
