import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { Index } from 'lunr'
import { GatsbyImage } from 'gatsby-plugin-image'
import slugify from 'slugify'
import Hero from '../components/hero'

import Layout from '../components/layout/layout'
import Search from '../components/ui-kit/search'
import Container from '../components/layout/container'
import { BREAKPOINTS, ANIMATION } from '../styles/cssVariables'
import ProfileCard from '../components/ui-kit/profileCard'
import SEO from '../components/layout/seo'

const HeroWrapper = styled.section`
  background-color: var(--color-skobeloff);
`

const HeroContainer = styled(Container)`
  @media (min-width: ${BREAKPOINTS.smlUp}px) {
    padding-right: 0;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    > div {
      flex-direction: column;
    }
  }
`

const HeroPanel = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
  
`

const HeroHeading = styled.h1`
  margin-top: 60px;
  margin-bottom: 25px;
  color: #fff;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: calc(45px - 13px);
  }
`


const ResultsEmptyContainer = styled.div`
  grid-column: 1 / span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 200px 0 300px;

  text-align: center;
`

const ResultsEmptyMessage = styled.p`
  margin-bottom: 2em;
`

const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 26px 30px;

  padding-top: 100px;
  padding-bottom: 195px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 60px;
    padding-bottom: 150px;

    grid-template-columns: 1fr;
    gap: 20px;
  }
`

const CTAButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;
  background-color: var(--color-blue);
  color: #fff !important;
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`

/**
 * Renders search results.
 * Includes a number of elements from the homepage inc. banner.
 * Renders results in similar style to directory.
 * We can access the results of the page GraphQL query via the data prop.
 */
const SearchPage = ({ data, location }) => {
  // const heroImage = data.contentfulPageDirectory.heroImage

  const seoTitle = data?.contentfulPageDirectory?.seoTitle
  const seoDescription = data?.contentfulPageDirectory?.seoDescription

  // We can read what follows the ?q= here
  // URLSearchParams provides a native way to get URL params
  // location.search.slice(1) gets rid of the "?"
  const params = new URLSearchParams(location.search.slice(1))
  const q = params.get('q') || ''

  // LunrIndex is available via page query
  const { store } = data.LunrIndex
  // Lunr in action here
  const index = Index.load(data.LunrIndex.index)
  let results = []

  try {
    // search() is a lunr method
    results = index.search(q).map(({ ref }) => {
      // Map search results to an array of {slug, name, bio} objects
      return {
        slug: ref,
        ...store[ref],
      }
    })
  } catch (error) {
    console.log(error)
  }

  return (
    <Layout location={location}>
      <SEO pageTitle={seoTitle} seoDescription={seoDescription} />

      <HeroWrapper>
        <HeroContainer flex={true} width={12}>
          <HeroPanel>
            <HeroHeading>
              {results.length} search result{results.length > 1 && 's'}
            </HeroHeading>

            <Search initialQuery={q} />
          </HeroPanel>

        </HeroContainer>
      </HeroWrapper>

      <Container width={12}>
        <ResultsGrid>
          {results.length ? (
            results.map((result) => {
              return (
                <ProfileCard
                  key={slugify(result.name, { lower: true })}
                  profile={result}
                />
              )
            })
          ) : (
            <ResultsEmptyContainer>
              <ResultsEmptyMessage>
                Sorry we couldn't find what you were looking for. Help us to
                find your perfect match!
              </ResultsEmptyMessage>

              <CTAButton         
                  href="https://form.typeform.com/to/TMKe36gc"
                  rel="noreferrer"
                  target="_blank">
               
                  Matchmaker Service
         
              </CTAButton>
            </ResultsEmptyContainer>
          )}
        </ResultsGrid>
      </Container>
    </Layout>
  )
}

export default SearchPage

export const searchResultsQuery = graphql`
  query SearchResultsQuery {
    LunrIndex

    contentfulPageDirectory {
      seoTitle
      seoDescription
    }
  }
`
