import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BREAKPOINTS, ANIMATION, LAYOUT } from '../styles/cssVariables'
import { isAbsoluteUrl } from '../utils/utils'
import Container from './layout/container'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'


const HeroWrapper = styled.section`
padding-top: 110px;
padding-bottom: 80px;
// @media (max-width: ${BREAKPOINTS.med}px) {
//   padding-bottom: 50px;  
//   padding-top: 50px;
// }

background: linear-gradient(180deg, #006163 0%, #006163 0%, #006163 0.01%, #1C7876 0.02%, #006163 0.03%, #006163 0.04%, rgba(0, 97, 99, 0.45) 100%);
  color: var(--color-white);
  overflow: hidden;


  }
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 50px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 40px;

  }
  // @media (max-width: 577px) {
  //   padding-bottom: 0;
  // }
`





const HeroPanelNoImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 70%;
  margin-bottom: -20px;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
`
const HeroContainer = styled(Container)`
  @media (max-width: ${BREAKPOINTS.med}px) {
    > div {
      flex-direction: column;
    }
  }
`



const HeroTitle = styled.h1`
    margin-bottom: 25px;
    color: var(--color-white);
   
`
const HeroBodyText = styled.p`
color: var(--color-white-85);
`


const CTAButtonNoImage = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid var(--color-white);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white) !important;
  color: var(--color-skobeloff) !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-white) !important;
    background-color: transparent !important;  
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }


  
`
const HeroContainerNoImage = styled(Container)`
  display: flex;
  
  @media (max-width: 1300px) {
    display: unset !important;
  }
`

const CTAButtonGatsbyLinkNoImage = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  border: 1px solid var(--color-white);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 25px;
 margin-left: auto;
  margin-right: auto;
  background-color: var(--color-white) !important;
  color: var(--color-skobeloff) !important;

  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-white) !important;
    background-color: transparent !important;  
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }

  
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

const Hero = (props) => {
  return (
    <>
    <HeroWrapper>
      <HeroContainer flex={true} width={12}>
        <HeroPanelNoImage>
          <HeroTitle>{props.heading}</HeroTitle>
          <HeroBodyText>{props.subheading}</HeroBodyText>
          {props.ctaText && (
            <>
              {isAbsoluteUrl(props.ctaDestination) ? (
                <CTAButtonNoImage
                  href={props.ctaDestination}
                  target="__blank"
                  rel="nofollow"
                >
                  {props.ctaText}
                  <Arrow />
                </CTAButtonNoImage>
              ) : (
                <CTAButtonGatsbyLinkNoImage to={props.ctaDestination}>
                  {props.ctaText}
                  <Arrow />
                </CTAButtonGatsbyLinkNoImage>
              )}   
            </>
        )}
        </HeroPanelNoImage>
        
      </HeroContainer>
    </HeroWrapper>
    </>

  )
}



export default Hero
